// The Home page of the app. Shows recents, quick access, etc.

// Import dependencies
import React, { Component, Suspense } from 'react';
import { Link, Redirect } from 'react-router-dom';    // Allows to link to different views?
import Sidebar from '../components/_AdminSidebar'
import FetchFailHandler from '../components/_AdminFetchFailHandler'
import { withRouter } from 'react-router-dom';
import NavBar from "../components/_AdminNavBar"
import fetch from '../components/_AdminCustomFetch'

import moment from 'moment'
// import CampaignTable from '../components/campaign/CampaignTable'
import ReactTable from 'react-table'
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar} from 'react-bootstrap';
import ModalPopup from '../components/_AdminModalPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle, faExclamationTriangle, faTv } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import LoadingAnimation from '../components/_AdminLoadingAnimation'
import selectedOrgContext from '../components/_AdminOrganisationContext'
import Scrollbar from "react-scrollbars-custom";
import queryString from "query-string";
import Joyride from 'react-joyride';
import FileTable from '../components/_AdminArtworkTable'
import ApprovedFileTable from '../components/_AdminApprovedArtworkTable'
import DisapprovedFileTable from '../components/_AdminDisapprovedArtworkTable'
import "../styles/style.css"

// import RecentCampaigns from '../components/RecentCampaigns'

class HomePage extends Component {

    static contextType = selectedOrgContext

    constructor(props, context) {
        super(props, context) 
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {
            // user info
            firstName: '',

            // For advertisers & board owners
            teams: [''],    // IDs
            teamMemberNames: [],
            teamDataArray: [],   // Objects
            userObj: [],


            // Artwork approval params
            shouldTablesUpdate: false,

            isLoaded: false,


          }   // end this.state

      }   // end constructor



    // ------------------------------------------ UTILITY FUNCTIONS ----------------------------------------------------


     // Taking an array of promises, returns the first promise that does not get rejected
     // https://stackoverflow.com/questions/51160260/clean-way-to-wait-for-first-true-returned-by-promise
     firstTrue(promises) {
        const newPromises = promises.map(p => new Promise(
            (resolve, reject) => p.then(v => v && resolve(v), reject)
        ));
        newPromises.push(Promise.all(promises).then(() => false));
        return Promise.race(newPromises);
    }

    
    // TODO: Put this in Utilities.js
      checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                            return true;
                        }
                    })
                } else {return false}
        }
    }


     // ------------------------------------------- UTILITY FUNCTIONS ---------------------------------------------------

      componentDidMount = async () => {
        await this.checkPreviousStateAndRestore();
        await this.getData();
        
        // Check query strings for firstSignin = true
        const parsed = queryString.parse(this.props.location.search);
        console.log(parsed);

        if (parsed.firstSignin === "true") {
            // Show disclaimer
            console.log('Showing disclaimer')
            this.setState({
                isFirstSignin: true,
                firstSigninDisclaimerModal: {show: true}
            })
        }

        if (parsed.showTutorial === "true") {
            console.log('showing tutorial')
            this.setState({
                showTutorial: true
            })
        }

      }



    // Get campaign names of each campaign in an array
    createOrgNameMap = async (arrayOfCampaigns) => {
            var organisationNameMap = [];
            await Promise.all(arrayOfCampaigns.map(async campaign => {
                var fetchStatus = 0;
                if (campaign.parentOrg) {
                    await fetch('/api/organisation/' + campaign.parentOrg, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                            },
                        }).then(res => {
                            fetchStatus = res.status;
                            return res.json();
                        }).then(res => {
                            // Organisation retrieved
                            var org = res.org;
                            // populate an array of recent campaigns with their parent organisation
                            organisationNameMap[org._id] = org.orgName
                        }).catch(err => {
                            console.log('Failed to fetch campaign owners. Error: ' + err)
                            FetchFailHandler(this.props, this.state, fetchStatus)
                        })
                }   
            })).then(async () => {
                // Once all orgs have been populated
                await this.setState({
                    organisationNameMap: organisationNameMap
                }, () => {
                    console.log('set organisation name map: ' + JSON.stringify(this.state.organisationNameMap))
                })
            })
    }


    // Get team name from ID
    getTeamNameFromID = (teamID) => {
        //console.log('looking for: ' + teamID)
       // console.log('teamDataArray: ' + JSON.stringify(this.state.teamDataArray))
        var team = this.state.teamDataArray.find(elem => {
            return (elem._id === teamID)
        })

        if (typeof (team) !== 'undefined'){
            return team.teamName;
        }
        
    }

    //returns a srting for the redering of the team members names
    getTeamMembersNameString = (teamMembersNameList) => {
        var names_list_str = '';
        if (typeof (teamMembersNameList) !== 'undefined'){
            for (const name of teamMembersNameList){
                names_list_str += name + ", ";
            }
            //removing the , for the last one
            names_list_str = names_list_str.slice(0, -2)

        } 
        return names_list_str
    }

    // To populate first signin modal
    // Return AlphaDisclaimerModal on first signin
    getFirstSigninModalContent = () => {
        return (null);
            // <AlphaDisclaimerModal onClose={this.firstSigninDisclaimerModalClose}/>
        

    }

    // Get info about the parent organisation provided the context has been set
    getSelectedOrganisationDetails = async () => {
        // Obtain org information
        var orgFetchStatus = 0;
        // TODO: Fetch organisation from selectedOrgContext, not team because user might be from different teams in different orgs.
        if (this.context.selectedOrg) {
            console.log('selected org: ' + this.context.selectedOrg)
            await fetch('/api/organisation/' + this.context.selectedOrg, {      // TODO: Account for different organisations
                method: 'GET',
            }).then(res => {
                orgFetchStatus = res.status;
                return res.json()
            }).then(res => {
                                                    
                // Set state (update campaign in state)
                this.setState({
                    orgType: res.org.orgType,
                    // Set isLoaded
                    //isLoaded: true
                    
                })
            }).catch(err => {
                console.log('fetch error: ' + err)
                FetchFailHandler(this.props, this.state, orgFetchStatus)
            })
        }
    }

    //Given the ids of the team member, return a list of their names
    getTeamMemberNameFromTeamMembersId = async(teamMembers) => {
        //String var for the last and first names to go to
        var lastNameStr = ''
        var firstNameStr = ''
        //variables for storing and compiling the full names
        var induvidual_person_team_name = ''
        var newTeamMemberFirstLastNames = []
        await Promise.all(teamMembers.map(async item => {
            await fetch('/api/user/'+ item, {
                method: 'GET'
            }).then(async res => {
                return await res.json();
            }).then(async res => {
                // update the array of team members
                //if the user has not put their last names, then leave it ommit it
                if (res.lastName === undefined){
                    lastNameStr = ''
                } else{
                    lastNameStr = res.lastName
                }
                //same case but for the firstname
                if (res.firstName === undefined){
                    firstNameStr = ''
                }else{
                    firstNameStr = res.firstName
                }

                induvidual_person_team_name = firstNameStr + ' ' + lastNameStr
                
                newTeamMemberFirstLastNames.push(induvidual_person_team_name)

            }).catch(async err => {
                console.log('fetch error: ' + err)
                //FetchFailHandler(this.props, this.state, fetchStatus)
            })
        }))
       
    return newTeamMemberFirstLastNames;
        
    }
    
    // Populates the state
    getData = async () => {
        // Get Teams, Clients, and Campaigns managed.
        // This will work if the user is logged in.
        let fetchStatus = 0;
        cogoToast.loading('Getting all the information...')
        await fetch('/api/user/', 
        {
            method: 'GET',
        })
        .then(async res => {
            fetchStatus = res.status;
            return await res.json();
        })        // From express /profile GET route
        .then(async res => {
            await this.setState({
                teams: res.teams, // teamIDs
                firstName: res.firstName,
                recentsObj: res.recentPages ? res.recentPages : this.state.recentsObj
            })

                // Now that we know what teams the user belongs to, find all campaigns associated with these teams.
                var campaignList = [];
                var newTeamDataArray = [];

                // Get the organisation's details (e.g. org name, org type, etc.)
                await this.getSelectedOrganisationDetails();

                await Promise.all(this.state.teams.map(async teamID => {
                    // Fetch the team
                    let fetchStatus = 0;
                    await fetch('/api/team/'+teamID, {              // /dashboard/ returns a team object
                        method: 'GET'
                    }).then(res => {
                        // return JSON format
                        fetchStatus = res.status;
                        return res.json();
                    }).then(async res => {
                        // Get the team data and set the state
                        // Set all other state variables
                        //console.log('res.team: ' + JSON.stringify(res.team))
                        await newTeamDataArray.push(res.team)
                        await Promise.all(res.team.campaigns.map(async campaign => {
                            await campaignList.push(campaign)
                        
                        }))
                            
                        // Pushed all available campaigns into campaignList, now setstate
                        await this.setState({
                            isLoaded: true,
                            campaigns: campaignList,
                            teamDataArray: newTeamDataArray,
                            shouldTablesUpdate: false
                        }, () => {
                            cogoToast.success('Loaded everything!')
                        })
                            // await this.createOrgNameMap();
                    }).catch(err => {
                        console.log('fetch error: ' + err)
                        FetchFailHandler(this.props, this.state, fetchStatus)
                    })
                    
                    //TODO: Repeat for clients...
                // })  // end cogoToast
                })).then(async res => {
                    //converting the id number of the team members to their names

                    let names_list = await this.getTeamMemberNameFromTeamMembersId(this.state.teamDataArray[0].teamMembers)
                    //the list is now accessible via names_list
                    this.setState({
                        teamMemberNames: names_list
                    })
                    
                }).catch(err => {
                    console.log('fetch error: ' + err)
                    FetchFailHandler(this.props, this.state, fetchStatus)
                })
            })
        }

    // ------------------------------------------ HANDLER FUNCTIONS --------------------------------------------------



    // Modals
    firstSigninDisclaimerModalClose = () => {
        this.setState({ 
            firstSigninDisclaimerModal: {show: false },
        }, () => {
            
        })
        
    };

    // On artwork update (callback for the tables - when they change the files)
    onArtworkTableUpdate = () => {
        // Force state update/re-render of the tables
        this.setState({
            shouldTablesUpdate: true
        }, () => {
            // Force get new data -> sets shouldTablesUpdate = false
            console.log('shouldtablesupdate: ' + this.state.shouldTablesUpdate)
            this.getData();
        })

        
    }




    // ------------------------------------------ HANDLER FUNCTIONS --------------------------------------------------

    // ------------------------------------------ RENDER FUNCTIONS ---------------------------------------------------

    // Render team cards
        // Render cards corresponding to each team
        renderTeamCards = () => {
            if (this.state.isLoaded){
                console.log("teamDataArray") 
                console.log(this.state.teamDataArray)
                console.log("teamMemberNames 6595")
                console.log(this.state.teamMemberNames)
                return this.state.teamDataArray.map(team => (
                    <Card key = {team._id} style={{ width: '20vw' }} className = "general-info-cards pale-background magnify-hover mx-5 mt-5">
                        <Card.Body className='px-2 py-0'>
                                <Row className='py-0 my-0'>
                                    <Col className = "justify-content-center my-auto">
                                        <Row><h4 className='bold-text'>Team: '{team.teamName}'</h4></Row>
                                        <Row><p className='blue-grey-color'><b>Members:</b> {this.getTeamMembersNameString(this.state.teamMemberNames)}</p>
                                        </Row>
                                        <Row className ='float-right'>
                                            <Button key = {''}    // this.state.staffList[staffMember]
                                            className="text-button-small"
                                            onClick={() => {
                                                this.props.history.push('/dashboard/' + team._id)    // this.state.staffList[staffMember]._id
                                            }}>
                                            GO TO DASH <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                                        </Button>
                                        </Row>
                                        
                                    </Col>
                                </Row>
                                <Row className='float-right'>
                                    <Col>
                                        
                                    </Col>
                                </Row>
                        </Card.Body>
                    </Card>
                )) // end return
            } else {
                return (<LoadingAnimation />)
            }
        }


    // Render files as a table
    renderFileTable= () => {
        if (this.state.isLoaded){
            return (
                <Container fluid className='mb-0'>
                    <FileTable key={'fileTable'} onUpdate = {this.onArtworkTableUpdate} shouldUpdate = {this.state.shouldTablesUpdate}/>
                </Container>
            
            )
        } else {
            return (<LoadingAnimation />)
        }
    }



      render() {
        // Page content (set as a variable so that it can be the child of the sidebar)
        console.log('rendering homepage')
        var content = (
            <Scrollbar className='customScroll' noScrollX>
                <NavBar />
                { (this.state.showTutorial) ? <Joyride
                steps={this.state.joyRideSteps}
                run
                showSkipButton
                continuous
                showProgress
                locale = {{ back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }}
                styles={{
                    options: {
                      arrowColor: '#00232d',
                      backgroundColor: '#00232d',
                      //overlayColor: 'rgb(0,35,45, 0.98)',
                      primaryColor: '#3bd62b',
                      textColor: '#fff',
                      width: 900,
                      zIndex: 1000,
                    }
                  }}
                /> : ''}
                
                <Jumbotron fluid>
                    <Container fluid className='px-5'>
                        <h1 className='white-text'>Hey <b>{this.state.firstName || 'you'}</b>.</h1> 
                        <h3 className='white-text'>
                        It's great to see you (again). Here's where you left off.
                        </h3>
                    </Container>
                </Jumbotron>
                
                    <Jumbotron fluid bsPrefix="header-cards mb-5">
                    <Container fluid className="header-cards-content px-5 stepTwo">
                        {/* <h3 className="grey-text noto-sans bold-text "><b>RECENTLY UPLOADED ARTWORK</b></h3> */}
                            <CardGroup className=''>
                                {/* <RecentCampaigns/>  */}
                            </CardGroup>
                    </Container>
                    </Jumbotron>
                    
                    {/* <Row className = 'spacer my-5'></Row> */}

                    <Container fluid className = "px-0 my-5 stepOne pale-background">
                        <Row className='px-5 '>
                        {this.renderFileTable()}
                        </Row>
                        <Row>
                            <Col sm={6}> 
                                <DisapprovedFileTable onUpdate = {this.onArtworkTableUpdate} shouldUpdate = {this.state.shouldTablesUpdate}/>
                            </Col>
                            <Col sm={6}>
                                <ApprovedFileTable onUpdate = {this.onArtworkTableUpdate} shouldUpdate = {this.state.shouldTablesUpdate}/>
                            </Col>
                        </Row>
                    </Container>


                    <Container fluid className = "px-5 mb-5 mt-0 mx-0 stepThree pale-background">
                    <Row className='mt-5 mb-3'>
                            <Col md={6} lg={8}>
                                <h3 className="grey-text noto-sans my-auto "><b>LIST OF ADMINS</b></h3>
                                <p>Here are your teams. Go to each of their dashboards from here.</p>
                            </Col>
                        </Row>
                        {this.renderTeamCards()}
                        <Row className = 'spacer my-5'></Row>
                    </Container>

                </Scrollbar>)

          return(
            <>
                {content}
            </>
          )
      }




     // ------------------------------------------ RENDER FUNCTIONS ---------------------------------------------------     


      



}// end class

// Define contextType here. Not sure if needed (or if static contextType works)
HomePage.contextType = selectedOrgContext;

export default withRouter(HomePage)

